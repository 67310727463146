import { render, staticRenderFns } from "./gjcHosp.vue?vue&type=template&id=5cc5dfef&scoped=true"
import script from "./gjcHosp.vue?vue&type=script&lang=js"
export * from "./gjcHosp.vue?vue&type=script&lang=js"
import style0 from "./gjcHosp.vue?vue&type=style&index=0&id=5cc5dfef&prod&scoped=true&lang=less"
import style1 from "./gjcHosp.vue?vue&type=style&index=1&id=5cc5dfef&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc5dfef",
  null
  
)

export default component.exports