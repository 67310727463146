<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2021-03-12 16:46:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\appoint\appoint.vue
-->
<template>
  <div>
    <div class="title">
      <div class="title-txt">
        {{ hospData.shortHospName }}
      </div>
      <!-- <img src="../../assets/img/blue-shi.png" alt=""> -->
    </div>
    <div class="content">
      <div class="introduction" v-html="hospData.desc" />
    </div>    
    <!-- <div class="page_address">
            <div class="bold size28 dark-color">医院地址：</div>
            <div class="text"> {{ hospData.address }}</div>
        </div> -->
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'

export default {
    data(){
        return{
            hospData:{}
        }
    },
    mounted() {
        this.getQueryRegisterData()
    },
    onload() {

    },
    methods:{
        //获取号源列表
        getQueryRegisterData(){
            const params = {
                yydm: '' //医院代码（医院唯一标识）
            }
            gjcModel.getHospitalIntroductionData(params).then((result) => {
                console.log('医院介绍', result)
                this.hospData = result.data
            })
        }
    }
}
</script>
<style scoped lang="less">
.title{
    width: 100%;
    margin: 32px auto;
    display: flex;
    justify-content: center;
    img{
        width:32px;
        height: 32px;
        position: relative;
        top: 9px;
    }
    .title-txt{
        font-size: 36px;
        font-weight: bold;
        color: #3D3D3D;
        margin: 0 16px;
    }
}
.content{
    .introduction{ 
        margin: 0 24px 20px 24px;
        // width: 100%; 
        // margin-bottom: 3px; 
    }
    .text{
        // text-indent: 60px;
        font-size: 32px;
        color: #3D3D3D;
        line-height: 46px;
    }
}
.page_address{
    padding: 0 32px 32px;
    .text{
        // text-indent: 60px;
        font-size: 28px;
        color: #3D3D3D;
        line-height: 46px;
    }
}
</style>
<style>
    .introduction img{
        width: 100%;
        object-fit: fill;
    }
</style>